import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import {initReactI18next} from "react-i18next";



i18next
    .use(initReactI18next)
    .use(ChainedBackend).init({
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en'],
    ns: ['translation'],
    defaultNS: 'translation',
    backend: {
        backends: [
            HttpBackend
        ],
        backendOptions: [
            {
                loadPath:
                    "./locales/en.json"
            }
        ]
    }
})

export default i18next;