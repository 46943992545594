import React, {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useFSMStore} from 'stores/useFSMStore';
import {useDemoStore} from 'stores/useDemoStore';
import {useCameraStore} from 'stores/useCameraStore';
import {DefaultValues} from 'types/DefaultValues';

import './Overlay.scss';

type OverlayProps = {
    type: string;
    content: any;
    additionalState?: string;
};

export enum OverlayTypes {
    TEXT = 'text',
    SLIDESHOW = 'slideshow',
    VIDEO = 'video'
}

const OverlayText: React.FC<{ i18nPath: string }> = ({i18nPath}) => {
    const {t} = useTranslation();
    const {additionalState} = t(i18nPath, {returnObjects: true}) as OverlayProps;
    const setFSMState = useFSMStore(state => state.setFSMState);
    const setCanReturnToScreensaver = useDemoStore(state => state.setCanReturnToScreensaverFlag);
    const setNextState = useCallback(() => {
        if (additionalState) {
            setCanReturnToScreensaver(false);
            setFSMState(additionalState);
        }
    }, [additionalState, setCanReturnToScreensaver, setFSMState]);

    return (
        <div className={'text-container'}>
            <div className={'content'}>
                <Trans
                    i18nKey={i18nPath + '.content'}
                    components={{
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content
                        a: <a/>,
                        b: <b/>,
                        ul: <ul/>,
                        li: <li/>,
                        img: <img alt={i18nPath + 'img'}/>,
                        hr: <hr/>,
                        table: <table/>,
                        caption: <caption/>,
                        thead: <thead/>,
                        tbody: <tbody/>,
                        tr: <tr/>,
                        th: <th/>,
                        td: <td/>,
                        SetFSMState: <div onClick={setNextState}/> // clicking this tag will transition to the additionalState specified in en.json
                    }}
                />
            </div>
        </div>
    );
};

const OverlaySlideshow: React.FC<{ urls: Array<string>, nextState?: string }> = ({urls, nextState}) => {

    const setCanReturnToScreensaver = useDemoStore(state => state.setCanReturnToScreensaverFlag);
    const setTargetCameraOffset = useCameraStore(state => state.setTargetCameraOffset);
    useEffect(() => {
        setCurrentIndex(0);
        setCanReturnToScreensaver(false);
        setTargetCameraOffset(
            DefaultValues.overlayCameraOffset,
            600, // TODO CHANGE ACCORDING TO CSS ANIMATION
            0);
    }, [urls, setCanReturnToScreensaver, setTargetCameraOffset]);

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [slideshowActive, setSlideshowActive] = useState<boolean>(true);
    const lastIndex = urls.length - 1;
    const isFirst = currentIndex < 1;
    const isLast = currentIndex >= lastIndex;

    const showPreviousSlide = useCallback(() => {
        const previousIndex = isFirst ? lastIndex : currentIndex - 1;
        setCurrentIndex(previousIndex);
    }, [currentIndex, isFirst, lastIndex]);

    const showNextSlide = useCallback(() => {
        const nextIndex = isLast ? 0 : currentIndex + 1;
        setCurrentIndex(nextIndex);
    }, [currentIndex, isLast]);

    const toggleSlideshowActivation = useCallback(() => {
        setSlideshowActive(!slideshowActive);
    }, [slideshowActive]);

    useEffect(() => { // When index / urls array change
        const changeIndexTimeout = setTimeout(() => {
            if (!slideshowActive) return;
            showNextSlide();
        }, isLast ? 10000 : 300); //last image should be displayed longer

        return () => clearTimeout(changeIndexTimeout);
    }, [currentIndex, isLast, showNextSlide, slideshowActive, urls]);

    return (
        <div className={'slideshow-container'}>
            <div className="slideshow">

                {lastIndex > 0 &&
                    <div className="previous" onClick={showPreviousSlide}>
                        <img src={'./assets/images/nav_gauche.png'} alt={'previous slide'}/>
                    </div>}

                <div className={'slideshow-center'}>
                    <div className={'slideshow-content'}>
                        {urls.map((url, index) =>
                            <img key={index} style={{display: index === currentIndex ? '' : 'none'}} src={url}
                                 alt={url}/>)}
                    </div>
                    {lastIndex > 0 &&
                        <img className="pause" alt={'pause slideshow'} onClick={toggleSlideshowActivation}
                             src={slideshowActive ? './assets/images/pause.png' : './assets/images/play.png'}/>}
                </div>

                {lastIndex > 0 &&
                    <div className="next" onClick={showNextSlide}>
                        <img src={'./assets/images/nav_droite.png'} alt={'next slide'}/>
                    </div>}

            </div>
        </div>
    );
}

const OverlayVideo: React.FC<{ url: string, nextState?: string }> = ({url, nextState}) => {
    const setTargetCameraOffset = useCameraStore(state => state.setTargetCameraOffset);
    const setCanReturnToScreensaver = useDemoStore(state => state.setCanReturnToScreensaverFlag);
    useEffect(() => {
        setCanReturnToScreensaver(false);

        setTargetCameraOffset(
            DefaultValues.overlayCameraOffset,
            600, // TODO CHANGE ACCORDING TO CSS ANIMATION
            0
        );
    }, [setTargetCameraOffset, setCanReturnToScreensaver]);

    return (
        <div className={'video-container'}>
            <video key={url} muted={true} controls autoPlay>
                <source type={'video/mp4'} src={url}/>
            </video>
        </div>
    );
}

const OverlayContent: React.FC<{ overlay: OverlayProps }> = ({overlay}) => {
    const currentFSMState = useFSMStore(state => state.currentFSMState);
    if (!currentFSMState) return null;

    switch (overlay.type) {
        case OverlayTypes.TEXT:
            return <OverlayText i18nPath={currentFSMState + '.overlay'}/>;
        case OverlayTypes.SLIDESHOW:
            return <OverlaySlideshow urls={overlay.content} nextState={overlay.additionalState}/>;
        case OverlayTypes.VIDEO:
            return <OverlayVideo url={overlay.content} nextState={overlay.additionalState}/>;
        default:
            return null;
    }

};

export const Overlay: React.FC = () => {
    const {t} = useTranslation();

    const currentFSMState = useFSMStore(state => state.currentFSMState);

    const [overlay, setOverlay] = useState<OverlayProps>(t(currentFSMState + '.overlay', {returnObjects: true}));

    useEffect(() => {
        setOverlay(t(currentFSMState + '.overlay', {returnObjects: true}))
    }, [t, currentFSMState]);

    if (!overlay) return null;

    return (
        <div className={'overlay'}>
            <OverlayContent overlay={overlay}/>
        </div>
    );
};
