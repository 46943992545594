import React from 'react';
import {useTranslation} from "react-i18next";

export interface CarouselItemDTO {
  thumbnailUrl?: string;
  state: string;
  label: string;
  content?: string;
}

export interface CarouselItemProps {
  item: CarouselItemDTO;
  onClick: (item: CarouselItemDTO) => void;
  selected: boolean;
  isAnItemSelected: boolean;
  color: string;
}

export const CarouselItem: React.FC<CarouselItemProps> = (props: CarouselItemProps) => {
  const {t} = useTranslation();

  return (
    <div className={'carousel-item'} onClick={() => props.onClick(props.item)}>

      {!props.isAnItemSelected && props.item.thumbnailUrl && <img src={props.item.thumbnailUrl} alt={props.item.label}/>}

      <div className={'carousel-label'} style={{backgroundColor: props.selected ? props.color : '#FFFFFF'}}>
        <p style={{color: props.selected ? '#FFFFFF' : '#454443'}}>
          {t(props.item.label)}
        </p>
      </div>

    </div>
  );
}