import React from "react";
import {useFSMStore} from "../../stores/useFSMStore";
import {FSMStates} from "../../types/FSMStates";
import {useDemoStore} from "../../stores/useDemoStore";

export const Footer: React.FC = () => {
    const {currentFSMState, setFSMState} = useFSMStore(fsmState => ({
        currentFSMState: fsmState.currentFSMState,
        setFSMState: fsmState.setFSMState
    }));

    const {setSelectedItem, selectedItem, openedDrawer, setOpenedDrawer} = useDemoStore(state => ({
        selectedItem: state.selectedItem,
        setSelectedItem: state.setSelectedItem,
        openedDrawer: state.openedDrawer,
        setOpenedDrawer: state.setOpenedDrawer,
    }));

    const isBackEnabled = currentFSMState !== FSMStates.demo.default || !!openedDrawer;
    const isHomeEnabled = currentFSMState !== FSMStates.demo.default || !!openedDrawer;

    const goHome = () => {
        if (currentFSMState !== FSMStates.demo.default) {
            setFSMState(FSMStates.demo.default);
            setOpenedDrawer(undefined);
        } else if (openedDrawer !== undefined) {
            setOpenedDrawer(undefined);
        }
    };
    const goBack = () => {
        if (selectedItem !== undefined) setSelectedItem(undefined);
        else if (currentFSMState?.includes('selected')) setFSMState(currentFSMState?.replace('selected', 'default'));
        else goHome();
    };

    return <div className={"footer"}>
        <img src={"./assets/images/button_back.png"}
             className={isBackEnabled ? 'back' : 'back nav-faded'}
             alt={"back"}
             onClick={() => isBackEnabled && goBack()}
        />
        <img src={"./assets/images/button_home.png"}
             className={isHomeEnabled ? 'home' : 'home nav-faded'}
             alt={"home"}
             onClick={() => isHomeEnabled && goHome()}
        />
        <div>
        </div>
    </div>;
}