import React, {useCallback} from 'react';
import {useSceneStore} from 'stores/useSceneStore';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import {useLoadAsset} from 'hooks/useLoadAsset';
import {Asset} from 'stores/useConfigStore';
import {useParseGLTF} from 'hooks/useParseGLTF';
import {useCameraController} from 'hooks/useCameraController';
import {useHandleObjectVisibility} from 'hooks/useHandleObjectVisibility';
import {useHandleAnimations} from 'hooks/useHandleAnimations';
import {useGotoState} from 'hooks/useGotoState';
import {useCameraOffset} from 'hooks/useCameraOffset';
import {useDemoStore} from "../../stores/useDemoStore";
import {useFSMStore} from "../../stores/useFSMStore";

const currentGLTFSelector = (state: any) => state.rawGLTF;
const GLTFIsDifferent = (old: GLTF, next: GLTF): boolean => !!next && (old?.scene.uuid === next.scene.uuid);
//FOLLOWING functions are working correctly but click detection is not working as expected.

// const nodesStateMap: Map<string, string> = new Map<string, string>();
// nodesStateMap.set("Dummy003_car", FSMStates.demo.micromachining.car.selected);
// nodesStateMap.set("Dummy002_phone", FSMStates.demo.micromachining.phone.selected);
// nodesStateMap.set("Dummy001_watch", FSMStates.demo.micromachining.watch.selected);
// nodesStateMap.set("Point007_Neuroscience_laser", FSMStates.demo.research.neuroscience + ".selected");
// nodesStateMap.set("Point010_High_harmonic_module", FSMStates.demo.research.high_harmonic_generation + ".selected");
// nodesStateMap.set("Point009_Three_Photon_module", FSMStates.demo.research.three_photon_imaging + ".selected");
//
// export function getRelatedState(obj: Object3D) : string | undefined {
//     if (!obj) return undefined;
//     if (obj.userData?.name && nodesStateMap.has(obj.userData?.name)) return nodesStateMap.get(obj.userData.name);
//     if (obj.parent === null || obj.parent === undefined) return undefined;
//
//     return getRelatedState(obj.parent);
// }

let origCoordinates = {x: -1, y: -1};

const Scene: React.FC<{ asset: Asset, regress: boolean }> = ({asset, regress}) => {
    const {scene} = useSceneStore(currentGLTFSelector, GLTFIsDifferent) || {};
    const currentFSMState = useFSMStore(state => state.currentFSMState);
    const setCanvasTouched = useDemoStore(state => state.setCanvasTouched);
    // const performance = useThree(state => state.performance);

    useLoadAsset(asset);
    useParseGLTF(scene);
    useCameraOffset();
    // useSceneBackground();
    useCameraController();
    useHandleObjectVisibility(scene);
    useHandleAnimations();

    // handle clicking on object and going to states
    const handleGotoState = useGotoState();

    const onPointerDown = useCallback((event) => {
        event.stopPropagation();
        origCoordinates.x = event?.clientX;
        origCoordinates.y = event?.clientY;
    }, [])

    const onPointerUp = useCallback((event) => {
        event.stopPropagation();

        let clientX = event?.clientX || -1;
        let clientY = event?.clientY || -1;

        if (Math.abs(origCoordinates.x - clientX) < 10 && Math.abs(origCoordinates.y - clientY) < 10 &&
            (currentFSMState?.includes('micromachining') || currentFSMState?.includes('research'))) {
            setCanvasTouched(true);
        }

        handleGotoState(event.object);
    }, [currentFSMState, handleGotoState, setCanvasTouched]);

    // RENDER
    if (!scene || !scene.uuid) return null;

    return (
        <>
            <primitive name={scene.name} object={scene} onPointerDown={onPointerDown} onPointerUp={onPointerUp}/>
            {/*<Annotations />*/}
            {/* DEBUG */}
            {/*<axesHelper />*/}

        </>
    );
};

export default Scene;