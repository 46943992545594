import React from "react";
import {CarouselItemDTO} from "./CarouselItem";
import {useTranslation} from "react-i18next";

export interface DrawerProps {
    label: string;
    onClick: (label: string) => void;
    openedDrawer: string | undefined;
    hideTitleWhenClosed: boolean;
    color: string;
    selectedItem?: CarouselItemDTO;
    closeIcon: string;
    openIcon: string;
    orientation?: string;
    minimumHeight?: boolean;
    className?: string;
}

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = (props: React.PropsWithChildren<DrawerProps>) => {
    const {t} = useTranslation();
    const deployed = props.label === props.openedDrawer;
    const isSelectedItemInCurrentDrawer = !!props.openedDrawer && !!props.selectedItem?.label && deployed
        && (props.selectedItem?.label.indexOf(props.openedDrawer.toLowerCase()) !== -1);

    return <div className={`drawer ${props.className || ''} ${deployed && 'drawer-maximized'}`}>
        <div className={"drawer-header"}
             style={{
                 // justifyContent: deployed ? 'space-between' : 'space-around'
             }}
             onClick={() => props.onClick(props.label)}>
            {(!props.hideTitleWhenClosed || deployed) && <>
                <h1 style={{color: props.color}}>
                    {props.label}
                </h1>
                <img className={`toggle-icon ${isSelectedItemInCurrentDrawer && 'rotate-left'}`}
                     alt={`toggle ${props.label}`}
                     src={deployed ? props.openIcon : props.closeIcon}/>
            </>}
            {props.hideTitleWhenClosed && !deployed && <p/>}
        </div>
        {deployed && <div
            className={`carousel-content ${!!props.minimumHeight && deployed && 'carousel-maximized'} ${!deployed && 'carousel-hidden'}`}
            style={{flexFlow: props.orientation === "vertical" ? 'column' : 'column'} /* TODO : fix this */}>
            {props.selectedItem &&
                <span className={'drawer-current-selected-title'}>
                        <span className={'forward-slash-drawer-title'}>/</span> {t(props.selectedItem.label)}</span>}
            {props.children}
        </div>}
        <div className={'drawer-border-trick'}/>
    </div>;
}