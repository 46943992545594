import {useEffect, useRef} from 'react';
import {useThree} from '@react-three/fiber';
import {useCameraStore} from 'stores/useCameraStore';
import {logHelper, tLogStyled} from 'utils/Logger';
import {CameraOffset} from 'types/CameraOffset';

export const useCameraOffset = (): void => {
  const camera = useThree(state => state.camera);
  const viewport = useThree(state => state.viewport);
  const previousCameraOffset = useCameraStore(state => state.previousCameraOffset);
  const setPreviousCameraOffset = useCameraStore(state => state.setPreviousCameraOffset);
  const currentCameraOffset = useRef<CameraOffset>(previousCameraOffset);
  const targetCameraOffset = useCameraStore(state => state.targetCameraOffset);
  const cameraOffsetTransition = useCameraStore(state => state.cameraOffsetTransition);

  useEffect(() => {
    // Camera Frustum offset
    const width = viewport.width * viewport.factor;
    const height = viewport.height * viewport.factor;

    tLogStyled(`[useCameraOffset] Setting camera view offset to 
    {fullWidth: ${Math.round(width * targetCameraOffset.zoomFactor)}, fullHeight: ${Math.round(height * targetCameraOffset.zoomFactor)}, 
    x: ${targetCameraOffset.x + width * (targetCameraOffset.zoomFactor - 1) / 2}, y: ${targetCameraOffset.y + height * (targetCameraOffset.zoomFactor - 1) / 2},
    width: ${width}, height: ${height}}`, logHelper.processing); // DEBUG

    let interval: number;
    const timeout = window.setTimeout(() => {
      const startTime = Date.now();

      setPreviousCameraOffset(currentCameraOffset.current); // if interval is stopped we start from the last offset
      clearInterval(interval);

      interval = window.setInterval(() => {
        const t = Math.max(0, Math.min(1, (Date.now() - startTime) / cameraOffsetTransition.duration));
        const easeOutQuint = (x: number): number => 1 - Math.pow(1 - x, 5);
        const offset = previousCameraOffset.lerp(targetCameraOffset, easeOutQuint(t));

        if (Date.now() >= startTime + cameraOffsetTransition.duration) {
          clearInterval(interval);
          setPreviousCameraOffset(offset);
        }

        camera.setViewOffset(
          width * offset.zoomFactor, height * offset.zoomFactor,
          offset.x + width * (offset.zoomFactor - 1) / 2, offset.y + height * (offset.zoomFactor - 1) / 2,
          width, height
        );
      }, 16);

    }, cameraOffsetTransition.delay);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, viewport, targetCameraOffset, cameraOffsetTransition, setPreviousCameraOffset]);
};