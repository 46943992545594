import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';

import 'index.scss';

ReactDOM.render(

    <React.StrictMode>
        <React.Suspense fallback={<></>}>
            <App />
        </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
