import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {Canvas} from '@react-three/fiber';
import {AdaptiveEvents} from '@react-three/drei';
import {ACESFilmicToneMapping, GammaEncoding} from 'three';
import {useSizeClassStore} from 'stores/useSizeClassStore';
import {useConfigStore} from 'stores/useConfigStore';
import Scene from 'components/WebGL/Scene';
import CameraControls from 'components/Controls/CameraControls';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';
import {addClass, removeClass} from 'utils/HtmlClassUtils';
import RenderController from 'components/WebGL/RenderController';
import Effects from 'components/WebGL/Effects';
import Lighting from 'components/WebGL/Lighting';

import './WebGLCanvas.scss';
import ReturnToScreensaver from 'components/WebGL/ReturnToScreensaver';

window.addEventListener('contextmenu', function (e) {
  e.preventDefault();
});

type WebGLCanvasProps = {
  allowBlur?: boolean
};

const WebGLCanvas: React.FC<WebGLCanvasProps> = ({allowBlur}) => {
  const webglDivRef = useRef<HTMLDivElement>(null);
  const sizeClass = useSizeClassStore(state => state.sizeClass);
  const {glb} = useConfigStore(state => state.config) || {};
  const currentFSMState = useFSMStore(state => state.currentFSMState);
  const [shouldRegress, setRegress] = useState<boolean>(false);

  //#region Blur WebGL DIV

  const handleBlur = useCallback(() => {
    if (currentFSMState === FSMStates.loading ||
      currentFSMState === FSMStates.loaded
      // || currentFSMState === FSMStates.screenSaver
    ) {
      setRegress(true);
      addClass(webglDivRef, 'blurred');
    } else {
      setRegress(false);
      removeClass(webglDivRef, 'blurred');
    }
  }, [currentFSMState]);

  // State Management
  useEffect(() => {
    if (allowBlur) handleBlur();
  }, [allowBlur, currentFSMState, handleBlur]);

  //#endregion

  return (
    <div
      ref={webglDivRef}
      className={`webgl-canvas ${sizeClass}`}
    >
      <Canvas
        shadows={true}
        dpr={[1, 1]}
        performance={{min: 0.1}}
        gl={{antialias: true, preserveDrawingBuffer: true/*, alpha: false*/}}
        onCreated={({gl}) => {
          gl.toneMapping = ACESFilmicToneMapping;
          gl.outputEncoding = GammaEncoding;
        }}
        frameloop={'demand'}
        // mode={'concurrent'}
        /* TODO: pause when idle
            see: https://docs.pmnd.rs/react-three-fiber/advanced/scaling-performance#on-demand-rendering */
        /* TODO: add regression when fps too low
            see: https://docs.pmnd.rs/react-three-fiber/advanced/scaling-performance#movement-regression */
      >

        <CameraControls/>
        <Suspense fallback={null}>

          <ReturnToScreensaver/>
          <RenderController/>
          <Lighting/>
          {/*<BackgroundSphere/>*/}

          {/*<AdaptiveDpr />*/}
          <AdaptiveEvents/>

          {glb && <Scene asset={glb} regress={shouldRegress}/>}

          <Effects/>
        </Suspense>

      </Canvas>
    </div>
  );
};

export default WebGLCanvas;