import create from 'zustand';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import {Object3D} from 'three/src/core/Object3D';
import {AnimationAction, AnimationClip} from 'three/src/Three';

export const cloneGLTF = (gltfObject: GLTF): GLTF => {
  const {scene, ...rest} = gltfObject;
  return {scene: scene.clone(), ...rest} as GLTF;
};

export type Object3DTypedArray = { [key: string]: Object3D[] };

export type SceneProps = {
  rawGLTF: GLTF | null;
  setCurrentGLTF: (gltfObject: GLTF) => void;

  // parsedScene: Object3D | null;
  // setParsedScene: (scene: Object3D) => void;
  sortedObjects: Object3DTypedArray;
  setSortedObjects: (sortedObjects: Object3DTypedArray) => void;

  hoveredObject: Object3D | null;
  setHoveredObject: (object?: Object3D) => void;

  animations: AnimationClip[];
  setAnimations: (animations: AnimationClip[]) => void;

  actions: {[key in AnimationClip['name']]: AnimationAction | null;};
  setActions: (actions: {[key in AnimationClip['name']]: AnimationAction | null;}) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  loadedBytes: number;
  percentageLoaded: number;
  setLoadedBytes: (loadedBytes: number, totalSize: number) => void;
  resetLoadedBytes: () => void;

  loadingError: Error | null;
  setLoadingError: (error: Error | null) => void;
}

export const useSceneStore = create<SceneProps>(set => ({
  rawGLTF: null,
  setCurrentGLTF: (gltfObject) => {
    // set({rawGLTF: cloneGLTF(gltfObject)});
    set({rawGLTF: gltfObject}); // if using cloned object, animations wont work
  },

  // parsedScene: null,
  // setParsedScene: (scene) => set({parsedScene: scene}),

  sortedObjects: {},
  setSortedObjects: (sortedObjects) => set({sortedObjects: sortedObjects}),

  hoveredObject: null,
  setHoveredObject: (object) => set({hoveredObject: object || null}),

  animations: [],
  setAnimations: (animations) => set({animations: animations}),

  actions: {},
  setActions: (actions) => set({actions: actions}),

  isLoading: true,
  setIsLoading: (isLoading) => set({isLoading: isLoading}),

  loadedBytes: 0,
  percentageLoaded: 0,
  setLoadedBytes: (loadedBytes, totalSize) => {
    const percentageLoaded = (totalSize) ? 100 * loadedBytes / totalSize : 0;
    set({loadedBytes: loadedBytes, percentageLoaded: percentageLoaded});
  },
  resetLoadedBytes: () => set({loadedBytes: 0, percentageLoaded: 0}),

  loadingError: null,
  setLoadingError: (error) => set({loadingError: error})
}));
