import React, {useCallback, useEffect, useState} from 'react';
import {FSMStates} from 'types/FSMStates';
import {useFSMStore} from 'stores/useFSMStore';
import {useTranslation} from 'react-i18next';

import './Screensaver.scss'

import {useCameraStore} from 'stores/useCameraStore';
import {DefaultValues} from 'types/DefaultValues';
import {useControlsStore} from "../../stores/useControlsStore";
import {useDemoStore} from "../../stores/useDemoStore";

type ScreensaverItem = { translationKey: string };

const SPEED_FACTOR = 2;
export const ScreensaverItems: Array<ScreensaverItem> = [
    {translationKey: "first"},
    {translationKey: "second"},
    {translationKey: "third"}
]

export const Screensaver: React.FC = () => {
    const setFSMState = useFSMStore(state => state.setFSMState);
    const setSelectedItem = useDemoStore(state => state.setSelectedItem);
    const setTargetCameraOffset = useCameraStore(state => state.setTargetCameraOffset);
    const setCameraConfig = useControlsStore(state => state.setConfig);
    const [itemsIndex, setItemsIndex] = useState<number>(0);
    const azimuthalAngle = useCameraStore(state => state.currentAzimutalAngle);
    const {t} = useTranslation();

    setSelectedItem(undefined);
    setCameraConfig({enablePan: false, enableRotate: true, autoRotate: true, enableZoom: false, enabled: true});

    useEffect(() => {
        const offsetAzimuth = azimuthalAngle + Math.PI;
        const newIndex = Math.floor(offsetAzimuth / (Math.PI * 2) * ScreensaverItems.length * SPEED_FACTOR) % ScreensaverItems.length;
        if (newIndex !== itemsIndex) setItemsIndex(newIndex);
    }, [azimuthalAngle, itemsIndex])

    useEffect(() => {
        setTargetCameraOffset(DefaultValues.screensaverCameraOffset);
    }, [setTargetCameraOffset]);

    const onClick = useCallback(() => {
        setFSMState(FSMStates.demo.default)
    }, [setFSMState]);

    const title = t(`page.screensaver.${ScreensaverItems[itemsIndex % ScreensaverItems.length].translationKey}.title`).toUpperCase();
    const content = t(`page.screensaver.${ScreensaverItems[itemsIndex % ScreensaverItems.length].translationKey}.content`);

    return (
        <div className={'screensaver'}>
            <div className={'text-bottom'}>
                <h1 style={{
                    color: '#454443'
                }}>
                    <span className={"forward-slash"}>/</span>{title}
                </h1>
                <p>{content}</p>
            </div>
            <img onClick={onClick} onTouchStart={onClick} src={'assets/images/arrow_right.png'} className={"screensaver-next"} alt={"next"}/>
        </div>
    );
};