import {useEffect} from 'react';
import {useMeasure} from 'react-use';
import {useSizeClassStore} from 'stores/useSizeClassStore';
import {UseMeasureRef} from 'react-use/lib/useMeasure';

const useSizeClass = (): UseMeasureRef<HTMLDivElement> => {
  const [appRef, {width}] = useMeasure<HTMLDivElement>();

  const setSizeClass = useSizeClassStore(state => state.setSizeClass);

  // Define size className
  useEffect(() => {
    if (width) setSizeClass();
  }, [setSizeClass, width]);

  return appRef;
};

export {useSizeClass};