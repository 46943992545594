import React, {useEffect, useState} from 'react';

import './Header.scss';
import {useFSMStore} from "../../stores/useFSMStore";
import {FSMStates} from "../../types/FSMStates";

export const Header: React.FC = () => {
    const currentFSMState = useFSMStore(state => state.currentFSMState);
    const setFSMState = useFSMStore(state => state.setFSMState);
    const [isScreensaver, setIsScreensaver] = useState(false);
    useEffect(() => setIsScreensaver([
        FSMStates.loading,
        FSMStates.loaded,
        FSMStates.loadingError,
        FSMStates.screenSaver
    ].includes(currentFSMState || "")), [currentFSMState]);

    return (
        <div className={`header ${isScreensaver ? 'screensaver' : ''}`}>
            <img src={"assets/images/satsuma_x_logo.png"} alt={'logo'}
                 onClick={() => setFSMState(FSMStates.screenSaver)} className={`${isScreensaver ? 'logo-screensaver' : 'logo'}`}/>
        </div>
    );
};