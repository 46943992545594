import React, {useCallback, useEffect} from 'react';
// import { Stats } from '@react-three/drei'; // DEBUG
import {useRenderStore} from 'stores/useRenderStore';
import {useIdle} from 'react-use';
import {useFrame, useThree} from '@react-three/fiber';
import {DefaultValues} from 'types/DefaultValues';

// import './WebGLCanvas.scss'; // DEBUG used for Stats position with .stats className

const RenderController: React.FC = () => {
  const {setIsIdle, setForceRender, forceRender, pauseRender} = useRenderStore(state => ({
    setIsIdle: state.setIsIdle,
    forceRender: state.forceRender,
    setForceRender: state.setForceRender,
    pauseRender: state.pauseRender
  }));
  const isIdle = useIdle(DefaultValues.renderPauseDelay, false); // events: ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']
  const invalidate = useThree(state => state.invalidate);
  const set = useThree(state => state.set);

  const shouldRender = useCallback(() => {
    if ((!isIdle && !pauseRender) || forceRender) {
      set({frameloop: 'demand'});
      invalidate();
    }
  }, [forceRender, invalidate, isIdle, pauseRender, set]);

  useFrame((state) => {
    shouldRender();
  });

  useEffect(() => {
    if (DefaultValues.renderPauseDelay > 0) {
      setIsIdle(isIdle);
      shouldRender();
    } else {
      setForceRender(true);
    }
  }, [isIdle, setForceRender, setIsIdle, shouldRender]);

  return null;

  // DEBUG FPS
  // return <Stats
  //   showPanel={0} // Start-up panel (default=0)
  //   className="stats" // Optional className to add to the stats container dom element
  // />;
};

export default RenderController;