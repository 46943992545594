import React, {useMemo, useRef} from 'react';
import {Environment} from '@react-three/drei';
import {SpotLight, Vector2} from 'three';

const Lighting: React.FC = () => {
  const light = useMemo(() => {
      const light = new SpotLight(0xffffff);
      light.intensity = 1;
      light.penumbra = 1;
      light.angle = .5;
      light.castShadow = true;
      light.shadow.mapSize = new Vector2(1024, 1024);
      light.shadow.bias = -0.0001;
      return light;
    }, []);

  const lightRef = useRef();
  // useHelper(lightRef, SpotLightHelper, 'cyan');

  return (
    <>
      {/*<Environment preset={'warehouse'} background={false} />*/}
      <Environment files={'./assets/hdri/empty_warehouse_01_1k.hdr'} background={false} />
      {/*<ambientLight intensity={1.5} />*/}
      {/*<ContactShadows rotation={[Math.PI / 2, 0, 0]} position={[0, -30, 0]} opacity={0.6} width={130} height={130} blur={1} far={40} />*/}
      <primitive ref={lightRef} object={light} position={[0, 15, -3]} />
      <primitive object={light.target} position={[0, 0, -3]} />
    </>
  );
};

export default Lighting;