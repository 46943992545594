import React from 'react';
import {useTranslation} from "react-i18next";

export interface TextCarouselItemDTO {
  state: string;
  label: string;
}

export interface TextCarouselItemProps {
  item: TextCarouselItemDTO;
  onClick: (item: TextCarouselItemDTO) => void;
  selected: boolean;
  isAnItemSelected: boolean;
  color: string;
}

export const TextCarouselItem: React.FC<TextCarouselItemProps> = (props: TextCarouselItemProps) => {
  const {t} = useTranslation();

  return (
    <div className={'text-carousel-item'} onClick={() => props.onClick(props.item)}>
      <div>
        <p style={{fontWeight: props.selected ? 'bold' : 'normal', color: '#454443', margin: 0, padding:0}}>
          <span className={'forward-slash-carousel-content'}>/</span> {t(props.item.label)}
        </p>
      </div>

    </div>
  );
}