import create from 'zustand';
import config from 'assets/config.json';

//#region Plumbing
export type ConfigProps = {
  config: Config,
};

export type Asset = {
  uri: string,
  filesize: number
};

export type Config = {
  project: { name: string },
  glb: Asset
} | null;
//#endregion

export const useConfigStore = create<ConfigProps>(() => ({
  config: config,
}));
