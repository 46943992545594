import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSizeClass} from 'hooks/useSizeClass';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import WebGLCanvas from 'components/WebGL/WebGLCanvas';
import LoadingFeedback from 'components/Pages/LoadingFeedback';

import {Screensaver} from 'components/Pages/Screensaver';
import {Demo} from 'components/Pages/Demo';
import {Header} from 'components/Pages/Header';

import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';

import './App.scss';

const App: React.FC = () => {
  const appRef = useSizeClass();
  const {t} = useTranslation();
  const currentFSMState = useFSMStore(state => state.currentFSMState);

  useEffect(() => {
    document.title = t('page.title');
  }, [t]);

  return (
    <div ref={appRef} className="app">
      {/*<DebugOverlay />*/}

      <Header/>

      {currentFSMState === FSMStates.loading && <LoadingFeedback/>}

      {currentFSMState === FSMStates.screenSaver && <Screensaver/>}
      {currentFSMState?.includes('demo') && <Demo/>}

      <WebGLCanvas/>
      {/*<Overlay/>*/}
    </div>
  );
};

export default App;
