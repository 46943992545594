// TODO: update regularly from amplitude-systems-front project...

export const FSMStates = {
    loading: 'loading',
    loaded: 'loaded',
    loadingError: 'loadingError',
    screenSaver: 'screenSaver',
    demo: {
        default: 'demo.default',
        modules: {
            harmonic: 'demo.modules.harmonic',
            pulse_duration_compressing: 'demo.modules.pulse_duration_compressing',
            fiber_delivery: 'demo.modules.fiber_delivery',
            optical_parametric_amplifier: 'demo.modules.optical_parametric_amplifier'
        },
        options: {
            pulse_on_demand: 'demo.options.pulse_on_demand',
            burst: 'demo.options.burst',
            high_ablation: 'demo.options.high_ablation'
        },
        research: {
            neuroscience: {
                default: 'demo.research.neuroscience.default',
                selected: 'demo.research.neuroscience.selected'
            },
            three_photon_imaging: {
                default: 'demo.research.three_photon_imaging.default',
                selected: 'demo.research.three_photon_imaging.selected'
            },
            high_harmonic_generation: {
                default: 'demo.research.high_harmonic_generation.default',
                selected: 'demo.research.high_harmonic_generation.selected'
            }
        },
        micromachining: {
            watch: {
                default: 'demo.micromachining.watch.default',
                selected: 'demo.micromachining.watch.selected'
            },
            phone: {
                default: 'demo.micromachining.phone.default',
                selected: 'demo.micromachining.phone.selected'
            },
            car: {
                default: 'demo.micromachining.car.default',
                selected: 'demo.micromachining.car.selected'
            }
        }
    }
};
