import React, {useCallback, useEffect, useRef} from 'react';
import {OrbitControls, TrackballControls} from '@react-three/drei';
import {
  CameraControls as CameraControlsImpl,
  OrbitControlsExp as OrbitControlsImpl,
  TrackballControlsExp as TrackballControlsImpl
} from 'three-stdlib';
import {useFrame} from '@react-three/fiber';
import {ControlsProps, useControlsStore} from 'stores/useControlsStore';
import {Vector3} from 'three';
import {useCameraStore} from "../../stores/useCameraStore";

const cloneControlsTarget = (from: CameraControlsImpl, to: CameraControlsImpl) => {
  if (!from || !to) return;
  const target = from.target;
  //@ts-expect-error update() is private
  from.update();
  to.target.set(target.x, target.y, target.z);
  // @ts-expect-error update() is private
  to.update();
};

const CameraControls: React.FC = () => {
  const orbitControlsRef = useRef<OrbitControlsImpl>(null);
  const trackballControlsRef = useRef<TrackballControlsImpl>(null);
  const setCurrentAzimuthalAngle = useCameraStore(state => state.setCurrentAzimuthalAngle);

  //#region Init ControlsStore functions

  useEffect(() => {

    const getTarget = () => {
      return orbitControlsRef.current?.target || new Vector3();
    };

    const setTarget = (pos: Vector3) => {
      if (orbitControlsRef.current)
        orbitControlsRef.current.target.set(pos.x, pos.y, pos.z);
    };

    // Init function in controlsStore
    const saveState = () => {
      orbitControlsRef.current?.saveState();
      // trackballControlsRef.current?.saveState();
    };

    const update = () => {
      // @ts-expect-error update is private
      orbitControlsRef.current?.update();
      // @ts-expect-error update is private
      trackballControlsRef.current?.update();
    };

    const reset = () => {
      orbitControlsRef.current?.reset();
      trackballControlsRef.current?.reset();
    };

    useControlsStore.setState({
      getTarget: getTarget,
      setTarget: setTarget,
      saveState: saveState,
      update: update,
      reset: reset
    });

  }, []);

  //#endregion

  //#region Update Controls Config

  const updateControls = useCallback((config: ControlsProps) => {
    // setConfigState(config);

    const orbit = orbitControlsRef.current || {} as CameraControlsImpl;
    const trackball = trackballControlsRef.current || {} as CameraControlsImpl;

    orbit.enabled = config.enabled;
    trackball.enabled = config.enabled;

    orbit.enableDamping = config.enableDamping;
    // @ts-expect-error staticMoving doesn't exist on CameraControls
    trackball.staticMoving = !config.enableDamping;

    orbit.enablePan = config.enablePan;
    orbit.enableRotate = config.enableRotate;
    orbit.autoRotate = config.autoRotate;
    orbit.autoRotateSpeed = config.autoRotateSpeed;
    // @ts-ignore
    trackball.noZoom = !config.enableZoom;

  }, []);

  /* Subscribe to Controls config updates */
  useEffect(() => {
    // using transient updates because changes occur too fast to be taken into account
    const controlsSubscription = useControlsStore.subscribe(
      (config: ControlsProps) => updateControls(config),
      state => state.config
    );

    return () => controlsSubscription(); // cancel subscription
  }, [updateControls]);

  //#endregion

  //#region Executed each frame

  //Clone OrbitControls target to TrackballControls
  useFrame(() => {
    if (orbitControlsRef?.current?.getAzimuthalAngle()) setCurrentAzimuthalAngle(orbitControlsRef?.current?.getAzimuthalAngle())
    // @ts-ignore
    cloneControlsTarget(orbitControlsRef.current, trackballControlsRef.current);
  });

  // Prevent camera from going below ground
  // useEffect(() => {
  //   orbitControlsRef.current?.addEventListener('change', () => {
  //     if (orbitControlsRef.current && orbitControlsRef.current.object.position.y <= 0.2) {
  //       orbitControlsRef.current.object.position.y = 0.2;
  //     }
  //   });
  // }, []);

  //#endregion

  return (
    <>
      {/*@ts-ignore*/}
      <OrbitControls ref={orbitControlsRef}
                     enablePan={true}
                     enableRotate={true}
                     enableZoom={false}
                     enabled={false}
                     enableDamping={false}
                     makeDefault={true}
      />
      {/*@ts-ignore*/}
      <TrackballControls ref={trackballControlsRef}
                         noPan={true}
                         noRotate={true}
                         noZoom={false}
                         enabled={false}
                         staticMoving={true}
                         zoomSpeed={.2} // default 1.2
                         // minDistance={.5} // TODO: adapt depending on target dimensions
                         maxDistance={12.5} // TODO: adapt depending on target dimensions
                         dynamicDampingFactor={0.1} // default 0.2
      />
      {/*@ts-ignore*/}
      {/*<CameraShake yawFrequency={0.05} rollFrequency={0.2} pitchFrequency={0.1} />*/}
    </>
  );
};

export default CameraControls;
