import React, {useEffect, useMemo, useState} from 'react';
import './Demo.scss'
import {Footer} from './Footer';
import {Drawer} from './Drawer';
import {CarouselItem, CarouselItemDTO} from './CarouselItem';
import {FSMStates} from 'types/FSMStates';
import {useFSMStore} from 'stores/useFSMStore';
import {useDemoStore} from 'stores/useDemoStore';
import {useCameraStore} from 'stores/useCameraStore';
import {DefaultValues} from 'types/DefaultValues';
import {useControlsStore} from "../../stores/useControlsStore";
import {TextCarouselItem} from "./TextCarouselItem";
import {useTranslation} from "react-i18next";
import {Overlay} from "./Overlay";

const MicroMachiningControls: React.FC = () => {
    const currentFSMState = useFSMStore(state => state.currentFSMState);
    const setFSMState = useFSMStore(state => state.setFSMState);
    const machiningSiblings = useMemo(() => [FSMStates.demo.micromachining.car.default, FSMStates.demo.micromachining.watch.default, FSMStates.demo.micromachining.phone.default], []);
    const [isVisible, setVisibiity] = useState<boolean>(machiningSiblings.includes(currentFSMState || ""));

    const {setSelectedItem, isCanvasTouched, setCanvasTouched} = useDemoStore(state => ({
        selectedItem: state.selectedItem,
        setSelectedItem: state.setSelectedItem,
        isCanvasTouched: state.isCanvasTouched,
        setCanvasTouched: state.setCanvasTouched
    }));

    useEffect(() => {
        if (!currentFSMState || !currentFSMState?.includes('micromachining')) return;

        setCanvasTouched(false);
        selectItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCanvasTouched])

    useEffect(() => {
        setVisibiity(machiningSiblings.includes(currentFSMState || ""));
    }, [currentFSMState, machiningSiblings])

    const prevItem = () => {
        if (!currentFSMState) return;

        const index = machiningSiblings.indexOf(currentFSMState);
        setFSMState(machiningSiblings[index < machiningSiblings.length - 1 ? index + 1 : 0]);
    };

    const nextItem = () => {
        if (!currentFSMState) return;

        const index = machiningSiblings.indexOf(currentFSMState)
        setFSMState(machiningSiblings[index > 0 ? index - 1 : machiningSiblings.length - 1]);
    };

    const selectItem = () => {
        setSelectedItem(Micromachining.find(value => value.state === currentFSMState));
    }

    if (!isVisible) return null;

    return <div className={'navigation-controls'}>
        <img src={"./assets/images/left_orange.png"} onClick={prevItem} alt={"prev"}/>
        <div className={'dumb-space'} onClick={selectItem}/>
        <img src={"./assets/images/right_orange.png"} onClick={nextItem} alt={"next"}/>
    </div>;
}

const ResearchControls: React.FC = () => {
    const currentFSMState = useFSMStore(state => state.currentFSMState);
    const setFSMState = useFSMStore(state => state.setFSMState);
    const researchSiblings = useMemo(() => [FSMStates.demo.research.neuroscience.default, FSMStates.demo.research.high_harmonic_generation.default, FSMStates.demo.research.three_photon_imaging.default], []);
    const [isVisible, setVisibiity] = useState<boolean>(researchSiblings.includes(currentFSMState || ""));

    const {setSelectedItem, setCanvasTouched, isCanvasTouched} = useDemoStore(state => ({
        selectedItem: state.selectedItem,
        setSelectedItem: state.setSelectedItem,
        isCanvasTouched: state.isCanvasTouched,
        setCanvasTouched: state.setCanvasTouched
    }));

    useEffect(() => {
        if (!currentFSMState || !currentFSMState?.includes('research')) return;

        setCanvasTouched(false);
        selectItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCanvasTouched])

    useEffect(() => {
        setVisibiity(researchSiblings.includes(currentFSMState || ""));
    }, [currentFSMState, researchSiblings])

    const prevItem = () => {
        if (!currentFSMState) return;

        const index = researchSiblings.indexOf(currentFSMState);
        setFSMState(researchSiblings[index < researchSiblings.length - 1 ? index + 1 : 0]);
    };

    const nextItem = () => {
        if (!currentFSMState) return;

        const index = researchSiblings.indexOf(currentFSMState)
        setFSMState(researchSiblings[index > 0 ? index - 1 : researchSiblings.length - 1]);
    };

    const selectItem = () => {
        setSelectedItem(Research.find(value => value.state === currentFSMState));
    }

    if (!isVisible) return null;

    return <div className={'navigation-controls'}>
        <img src={"./assets/images/left_green.png"} onClick={prevItem} alt={"prev"}/>
        <div className={'dumb-space'} onClick={selectItem}/>
        <img src={"./assets/images/right_green.png"} onClick={nextItem} alt={"next"}/>
    </div>;
}

const Modules: Array<CarouselItemDTO> = [
    {
        label: 'demo.modules.harmonic.label',
        thumbnailUrl: './assets/images/thumbnails/harmonic.png',
        state: FSMStates.demo.modules.harmonic
    },
    {
        label: 'demo.modules.pulse_duration_compressing.label',
        thumbnailUrl: './assets/images/thumbnails/pulse_duration_compression.png',
        state: FSMStates.demo.modules.pulse_duration_compressing
    },
    {
        label: 'demo.modules.fiber_delivery.label',
        thumbnailUrl: './assets/images/thumbnails/fiber_delivery.png',
        state: FSMStates.demo.modules.fiber_delivery
    },
    {
        label: 'demo.modules.optical_parametric_amplifier.label',
        thumbnailUrl: './assets/images/thumbnails/optical_parametric_amplifier.png',
        state: FSMStates.demo.modules.optical_parametric_amplifier
    }
]

const Options: Array<CarouselItemDTO> = [
    {label: 'demo.options.pulse_on_demand.label', state: FSMStates.demo.options.pulse_on_demand},
    {label: 'demo.options.burst.label', state: FSMStates.demo.options.burst},
    {label: 'demo.options.high_ablation.label', state: FSMStates.demo.options.high_ablation}
]

const Micromachining: Array<CarouselItemDTO> = [
    {
        label: 'demo.micromachining.car.label',
        state: FSMStates.demo.micromachining.car.default,
        content: 'demo.micromachining.car.content'
    },
    {
        label: 'demo.micromachining.phone.label',
        state: FSMStates.demo.micromachining.phone.default,
        content: 'demo.micromachining.phone.content'
    },
    {
        label: 'demo.micromachining.watch.label',
        state: FSMStates.demo.micromachining.watch.default,
        content: 'demo.micromachining.watch.content'
    }
]

const Research: Array<CarouselItemDTO> = [
    {
        label: 'demo.research.neuroscience.label',
        state: FSMStates.demo.research.neuroscience.default,
        content: 'demo.research.neuroscience.content'
    },
    {
        label: 'demo.research.three_photon_imaging.label',
        state: FSMStates.demo.research.three_photon_imaging.default,
        content: 'demo.research.three_photon_imaging.content'
    },
    {
        label: 'demo.research.high_harmonic_generation.label',
        state: FSMStates.demo.research.high_harmonic_generation.default,
        content: 'demo.research.high_harmonic_generation.content'
    }
]

export const Demo: React.FC = () => {
    const {t} = useTranslation();
    const {selectedItem, setSelectedItem, preselectedItem, setPreselectedItem, openedDrawer, setOpenedDrawer} = useDemoStore(state => ({
        selectedItem: state.selectedItem,
        setSelectedItem: state.setSelectedItem,
        preselectedItem: state.preselectedItem,
        setPreselectedItem: state.setPreselectedItem,
        setOpenedDrawer: state.setOpenedDrawer,
        openedDrawer: state.openedDrawer
    }));

    const controls = useControlsStore();
    const setFSMState = useFSMStore(state => state.setFSMState);
    const currentFSMState = useFSMStore(state => state.currentFSMState);
    const siblings = useFSMStore(state => state.currentFSMStateSiblings);
    const resetCameraOffset = useCameraStore(state => state.resetCameraOffset);
    const setTargetCameraOffset = useCameraStore(state => state.setTargetCameraOffset);

    useEffect(() => {
        if (!currentFSMState || (currentFSMState === FSMStates.demo.default)) {
            if (selectedItem) setSelectedItem(undefined);
            if (preselectedItem) setPreselectedItem(undefined);
            if (openedDrawer === 'MICROMACHINING' || openedDrawer === 'RESEARCH') {//FORCE CLOSE MICROMACHNING DRAWER WHEN IN DEMO DEFAULT
                setOpenedDrawer(undefined);
            }
            return;
        }

        if (currentFSMState.includes('micromachining') && openedDrawer === undefined) return setOpenedDrawer('MICROMACHINING')
        if (currentFSMState.includes('research') && openedDrawer === undefined) return setOpenedDrawer('RESEARCH')

        if (openedDrawer === 'MICROMACHINING') {
            const newPreselectedItem = Micromachining.find(item => item.state === currentFSMState);
            if (newPreselectedItem !== preselectedItem) setPreselectedItem(newPreselectedItem);
        }

        if (openedDrawer === 'RESEARCH') {
            const newPreselectedItem = Research.find(item => item.state === currentFSMState);
            if (newPreselectedItem !== preselectedItem) setPreselectedItem(newPreselectedItem);
        }
    }, [currentFSMState, openedDrawer, preselectedItem, selectedItem, setOpenedDrawer, setPreselectedItem, setSelectedItem])

    /*
    when selected item changes, checks if it is a micromachining default item.
    if it is, selects it in order to gain access to infos
    */
    useEffect(() => {
        if (openedDrawer === 'MICROMACHINING') {
            if (!selectedItem) {
                setFSMState(FSMStates.demo.micromachining.car.default);
            } else {
                setFSMState(selectedItem.state.replace('default', 'selected'));
            }
        } else if (openedDrawer === 'RESEARCH') {
            if (!selectedItem) {
                setFSMState(FSMStates.demo.research.neuroscience.default);
            } else {
                setFSMState(selectedItem.state.replace('default', 'selected'));
            }
        } else if (!openedDrawer) {
            setFSMState(FSMStates.demo.default);
        } else if (openedDrawer === 'MODULES') {
            if (currentFSMState?.includes('micromachining') || currentFSMState?.includes('research')) setFSMState(FSMStates.demo.default);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedDrawer, selectedItem, setFSMState])

    useEffect(() => {
        setTargetCameraOffset(DefaultValues.demoCameraOffset, 600);
    }, [setTargetCameraOffset]);

    return <div className={'demo'}>
        <div className={'controls'} style={{alignSelf: currentFSMState === FSMStates.demo.default ? 'start' : 'end'}}>
            <MicroMachiningControls/>
            <ResearchControls/>
            {currentFSMState === FSMStates.demo.default && !openedDrawer && <div className={"environment-choose"}>
                <p className={"environment-choose-label"}>{t('demo.ui.choose_environment')}</p>
                <div className={"buttons"}>
                    <div className={"button"}>
                        <p style={{color: '#FF5100'}}
                           onClick={() => {
                               setFSMState(FSMStates.demo.micromachining.car.default);
                               if (openedDrawer !== 'MICROMACHINING') setOpenedDrawer('MICROMACHINING');
                           }}>MICROMACHINING</p>
                    </div>
                    <div className={"button"}>
                        <p style={{color: '#006373'}}
                           onClick={() => {
                               setFSMState(FSMStates.demo.research.neuroscience.default)
                               if (openedDrawer !== 'RESEARCH') setOpenedDrawer('RESEARCH');
                           }}>RESEARCH</p>
                    </div>
                </div>
            </div>}
        </div>
        <div className={'accessory-view'}>
            {openedDrawer === 'MICROMACHINING' && <Drawer label={'MICROMACHINING'}
                                                          hideTitleWhenClosed={true}
                                                          onClick={() => {
                                                              if (openedDrawer === 'MICROMACHINING') {
                                                                  if (currentFSMState?.includes('selected')) {
                                                                      if (selectedItem !== undefined) setSelectedItem(undefined);
                                                                      setFSMState(currentFSMState?.replace('selected', 'default'));
                                                                      return;
                                                                  } else {
                                                                      return setFSMState(FSMStates.demo.default);
                                                                  }
                                                              }
                                                              setFSMState(FSMStates.demo.default);
                                                          }}
                                                          openedDrawer={openedDrawer}
                                                          selectedItem={selectedItem}
                                                          closeIcon={'./assets/images/modules_ferme.png'}
                                                          openIcon={'./assets/images/modules_actif.png'}
                                                          color={'#ff5100'}
                                                          orientation={"vertical"}
                                                          minimumHeight={true}
            >
                {!selectedItem &&
                    <><p className='choose-item'>{t('demo.ui.choose_item')}</p>
                        {Micromachining.map(module =>
                            <TextCarouselItem
                                key={module.label}
                                selected={module === preselectedItem}
                                isAnItemSelected={preselectedItem !== undefined}
                                onClick={() => {
                                    controls.setConfig({autoRotate: false});
                                    if (selectedItem === module) {
                                        if (siblings.length > 0) {
                                            setFSMState(siblings[0])
                                        }
                                    } else {
                                        setFSMState(module.state);
                                    }
                                    setSelectedItem(selectedItem === module ? undefined : module);
                                    resetCameraOffset();
                                }}
                                item={module}
                                color={'#ff5100'}
                            />)}
                        <div style={{height: '40px'}}/>
                    </>}
                {
                    selectedItem && <div className={'item-content'}>{selectedItem.content &&
                        <div dangerouslySetInnerHTML={{__html: t(selectedItem.content)}}/>}
                        <Overlay/>
                    </div>
                }
            </Drawer>}
            {openedDrawer === 'RESEARCH' && <Drawer label={'RESEARCH'}
                                                    hideTitleWhenClosed={true}
                                                    onClick={() => {
                                                        if (openedDrawer === 'RESEARCH') {
                                                            if (currentFSMState?.includes('selected')) {
                                                                if (selectedItem !== undefined) setSelectedItem(undefined);
                                                                return setFSMState(currentFSMState?.replace('selected', 'default'));
                                                            } else {
                                                                return setFSMState(FSMStates.demo.default);
                                                            }
                                                        }
                                                        setFSMState(FSMStates.demo.default);
                                                    }}
                                                    openedDrawer={openedDrawer}
                                                    selectedItem={selectedItem}
                                                    closeIcon={'./assets/images/options_ferme.png'}
                                                    openIcon={'./assets/images/options_actif.png'}
                                                    color={'#076777'}
                                                    orientation={"vertical"}
                                                    minimumHeight={true}
            >
                {!selectedItem &&
                    <><p className='choose-item'>{t('demo.ui.choose_item')}</p>
                        {Research.map(module =>
                            <TextCarouselItem
                                key={module.label}
                                selected={module === preselectedItem}
                                isAnItemSelected={preselectedItem !== undefined}
                                onClick={() => {
                                    controls.setConfig({autoRotate: false});
                                    if (selectedItem === module) {
                                        if (siblings.length > 0) {
                                            setFSMState(siblings[0])
                                        }
                                    } else {
                                        setFSMState(module.state);
                                    }
                                    setSelectedItem(selectedItem === module ? undefined : module);
                                    resetCameraOffset();
                                }}
                                item={module}
                                color={'#076777'}
                            />)}
                        <div style={{height: '40px'}}/>
                    </>}
                {
                    selectedItem && <div className={'item-content'}>{selectedItem.content &&
                        <div dangerouslySetInnerHTML={{__html: t(selectedItem.content)}}/>}
                        <Overlay/>
                    </div>
                }
            </Drawer>
            }
            {openedDrawer !== 'MICROMACHINING' && openedDrawer !== 'RESEARCH' && <Drawer label={''}
                                                                                         className={'hidden-landscape'}
                                                                                         hideTitleWhenClosed={true}
                                                                                         onClick={() => {
                                                                                         }}
                                                                                         openedDrawer={openedDrawer}
                                                                                         closeIcon={'./assets/images/modules_ferme.png'}
                                                                                         openIcon={'./assets/images/modules_actif.png'}
                                                                                         color={'#ff5100'}
                                                                                         orientation={"vertical"}
            >
            </Drawer>}
            <Drawer label={'MODULES'}
                    onClick={() => {
                        if (openedDrawer && openedDrawer !== 'MODULES') {
                            setFSMState(FSMStates.demo.default);
                            setOpenedDrawer('MODULES');
                        } else if (currentFSMState?.indexOf('modules') !== -1 && openedDrawer === 'MODULES') {
                            setFSMState(FSMStates.demo.default);
                        } else {
                            setOpenedDrawer(openedDrawer === 'MODULES' ? undefined : 'MODULES');
                        }
                    }}
                    selectedItem={selectedItem}
                    hideTitleWhenClosed={false}
                    openedDrawer={openedDrawer}
                    closeIcon={'./assets/images/modules_ferme.png'}
                    openIcon={'./assets/images/modules_actif.png'}
                    color={'#ff5100'}
            >
                {selectedItem !== undefined && <Overlay/>}
                {selectedItem === undefined && Modules.map(module =>
                    <CarouselItem
                        key={module.label}
                        selected={module === selectedItem}
                        isAnItemSelected={selectedItem !== undefined}
                        onClick={() => {
                            setSelectedItem(selectedItem === module ? undefined : module);
                            setFSMState(selectedItem === module ? FSMStates.demo.default : module.state);
                            resetCameraOffset();
                        }}
                        item={module}
                        color={'#ff5100'}
                    />)}
            </Drawer>
            <Drawer label={'OPTIONS'}
                    onClick={() => {
                        if (openedDrawer && openedDrawer !== 'OPTIONS') {
                            setFSMState(FSMStates.demo.default);
                            setOpenedDrawer('OPTIONS');
                        } else if ((currentFSMState?.indexOf('options') !== -1 && openedDrawer === 'OPTIONS')) {
                            setFSMState(FSMStates.demo.default);
                        } else {
                            setOpenedDrawer(openedDrawer === 'OPTIONS' ? undefined : 'OPTIONS');
                        }
                    }}
                    hideTitleWhenClosed={false}
                    openedDrawer={openedDrawer}
                    selectedItem={selectedItem}
                    closeIcon={'./assets/images/options_ferme.png'}
                    openIcon={'./assets/images/options_actif.png'}
                    color={'#076777'}
            >
                {selectedItem !== undefined && <Overlay/>}
                {selectedItem === undefined && Options.map(option =>
                    <CarouselItem
                        key={option.label}
                        selected={option === selectedItem}
                        isAnItemSelected={selectedItem !== undefined}
                        onClick={() => {
                            setSelectedItem(selectedItem === option ? undefined : option);
                            setFSMState(selectedItem === option ? FSMStates.demo.default : option.state);
                            resetCameraOffset();
                        }}
                        item={option}
                        color={'#076777'}
                    />)}
            </Drawer>
        </div>
        <Footer/>
    </div>;
}