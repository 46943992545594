import create from 'zustand';
import {CarouselItemDTO} from 'components/Pages/CarouselItem';

export type DemoStoreProps = {
    preselectedItem?: CarouselItemDTO;
    setPreselectedItem: (item?: CarouselItemDTO) => void;

    selectedItem?: CarouselItemDTO;
    setSelectedItem: (item?: CarouselItemDTO) => void;

    isOverlayVisible: boolean;
    setIsOverlayVisibleFlag: (value: boolean) => void;
    canReturnToScreensaver: boolean;
    setCanReturnToScreensaverFlag: (value: boolean) => void;

    setCanvasTouched: (value: boolean) => void;
    isCanvasTouched: boolean;

    setOpenedDrawer: (value: string | undefined) => void;
    openedDrawer: string | undefined;
}

export const useDemoStore = create<DemoStoreProps>((set) => ({
    selectedItem: undefined,
    setSelectedItem: (item) => set({selectedItem: item}),

    preselectedItem: undefined,
    setPreselectedItem: (item) => set({preselectedItem: item}),

    isOverlayVisible: false,
    setIsOverlayVisibleFlag: (value) => set({isOverlayVisible: value}),

    canReturnToScreensaver: true,
    setCanReturnToScreensaverFlag: (value) => set({canReturnToScreensaver: value}),

    isCanvasTouched: false,
    openedDrawer: undefined,
    setOpenedDrawer(value: string | undefined): void {
        set({openedDrawer: value})
    },
    setCanvasTouched: (value) => {
        set({isCanvasTouched: value})
    }
}));
