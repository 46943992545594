import {CameraOffset} from 'types/CameraOffset';
import {useSizeClassStore} from 'stores/useSizeClassStore';

const _portrait = window.screen.height > window.screen.width;

const _screensaverCameraOffsetPortrait = new CameraOffset({x: 0, y: 50, zoomFactor: 1.8});
const _demoCameraOffsetPortrait = new CameraOffset({x: 0, y: 200, zoomFactor: 1});
const _overlayCameraOffsetPortrait = new CameraOffset({x: 0, y: 200, zoomFactor: 1});
const _screensaverCameraOffsetLandscape = new CameraOffset({x: 0, y: 100, zoomFactor: 2.5});
const _demoCameraOffsetLandscape = new CameraOffset({x: 350, y: 0, zoomFactor: 1});
const _overlayCameraOffsetLandscape = new CameraOffset({x: 350, y: 0, zoomFactor: .75});

// pseudo responsivity...
useSizeClassStore.subscribe(
  (value: boolean) => {
    DefaultValues.screensaverCameraOffset = value ?? true ? _screensaverCameraOffsetPortrait : _screensaverCameraOffsetLandscape;
    DefaultValues.demoCameraOffset = value ?? true ? _demoCameraOffsetPortrait : _demoCameraOffsetLandscape;
    DefaultValues.overlayCameraOffset = value ?? true ? _overlayCameraOffsetPortrait : _overlayCameraOffsetLandscape;
  },
  state => state.portrait
);

export const DefaultValues = {

  renderPauseDelay: 0, // never
  returnToScreenSaverDelay: 600000, // 60s

  // Camera Controls
  lerpDuration: 0,
  orbitControlsEnabled: false,
  enableDamping: true,

  enableRotate: true,
  enablePan: false, // TODO PROD = false
  enableZoom: true,

  autoRotate: false, // TODO CHANGE TO FALSE
  autoRotateSpeed: -.66,

  screensaverCameraOffset: _portrait ?? true ? _screensaverCameraOffsetPortrait : _screensaverCameraOffsetLandscape,
  demoCameraOffset: _portrait ?? true ? _demoCameraOffsetPortrait : _demoCameraOffsetLandscape,
  overlayCameraOffset: _portrait ?? true ? _overlayCameraOffsetPortrait : _overlayCameraOffsetLandscape,
};
