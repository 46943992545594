import React, {useEffect} from 'react';
import {useFSMStore} from 'stores/useFSMStore';
import {useIdle} from 'react-use';
import {DefaultValues} from 'types/DefaultValues';
import {FSMStates} from 'types/FSMStates';
import {useDemoStore} from 'stores/useDemoStore';

const ReturnToScreensaver: React.FC = () => {
  const shouldReturnToScreenSaver = useIdle(DefaultValues.returnToScreenSaverDelay, false, ['mousedown', 'keydown', 'touchstart']);
  const {currentFSMState, setFSMState} = useFSMStore(state => ({
    currentFSMState: state.currentFSMState,
    setFSMState: state.setFSMState
  }));
  const {setSelectedItem, canReturnToScreensaver, setCanReturnToScreensaver} = useDemoStore(state => ({
    setSelectedItem: state.setSelectedItem,
    canReturnToScreensaver: state.canReturnToScreensaver,
    setCanReturnToScreensaver: state.setCanReturnToScreensaverFlag
  }));

  useEffect(() => {
    if (canReturnToScreensaver &&
      shouldReturnToScreenSaver &&
      currentFSMState !== FSMStates.screenSaver) {

      setCanReturnToScreensaver(true);
      setFSMState(FSMStates.screenSaver);
      setSelectedItem(undefined);
    }
  }, [canReturnToScreensaver, currentFSMState, setCanReturnToScreensaver, setFSMState, setSelectedItem, shouldReturnToScreenSaver]);

  return null;
};

export default ReturnToScreensaver;