import {ObjectMap, useLoader} from '@react-three/fiber';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
// @ts-expect-error : can't find declaration file...
import {MeshoptDecoder} from 'three/examples/jsm/libs/meshopt_decoder.module';


// Mainly copied from R3F useGLTF, but added onProgress callback to the signature


function extensions(useDraco: boolean, useMeshopt: boolean) {
  return (loader: any) => {

    if (useDraco) {
      const dracoLoader = new DRACOLoader();
      // dracoLoader.setDecoderPath(typeof useDraco === 'string' ? useDraco : 'https://www.gstatic.com/draco/v1/decoders/');
      dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
      loader.setDRACOLoader(dracoLoader);
    }

    if (useMeshopt) {
      loader.setMeshoptDecoder(MeshoptDecoder);
    }

  };
}

const useGLTF =
  (path: string, useDraco = true, useMeshOpt = true, onProgress?: (e: ProgressEvent) => void):
    GLTF & ObjectMap => {
    const gltf = useLoader(GLTFLoader, path, extensions(useDraco, useMeshOpt), onProgress);
    return gltf;
  };

useGLTF.preload = (path: string, useDraco = true, useMeshOpt = true) =>
  useLoader.preload(GLTFLoader, path, extensions(useDraco, useMeshOpt));

export {useGLTF};
