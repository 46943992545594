export const logHelper = {
  initComponent: 'font-weight:bold;color:white;background-color:black;',
  processing: 'font-weight:bold;color:deepskyblue;',
  loading: 'font-weight:bold;color:darkorange;',
  finishedLoading: 'font-weight:bold;color:limegreen;',
  event: 'font-weight:bold;color:magenta;',
  success: 'font-weight:bold;color:black;background-color:limegreen;',
  warning: 'font-weight:bold;color:white;background-color:darkorange;',
  error: 'font-weight:bold;color:white;background-color:red;',
  subdued: 'font-weight:bold;color:slategray;',
  start: 'font-style:italic;color:MediumSeaGreen;',
  pause: 'font-style:italic;color:orange;',
  stop: 'font-style:italic;color:firebrick;',
};

export const tLogStyled = (msg: unknown, style?: string, ...rest: unknown[]): void => {
  if (!!process.env.REACT_APP_LOG)
    // eslint-disable-next-line no-console
    console.log(`%c${msg}`, style, ...rest);
};

export const tLog = (...args: unknown[]): void => {
  if (process.env.REACT_APP_LOG)
    // eslint-disable-next-line no-console
    console.log(...args);
};

export const tLogWarn = (...args: unknown[]): void => {
  if (process.env.REACT_APP_LOG)
    // eslint-disable-next-line no-console
    console.warn(...args);
};

export const tLogError = (...args: unknown[]): void => {
  // if (process.env.REACT_APP_LOG)
  // eslint-disable-next-line no-console
  console.error(...args);
};
