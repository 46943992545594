import {useSceneStore} from 'stores/useSceneStore';
import {useCallback, useEffect, useState} from 'react';
import {Object3D} from 'three/src/core/Object3D';
import {useFSMStore} from 'stores/useFSMStore';
import {isAnnotationActive} from 'hooks/useActiveObject';

export const useGotoState = (): (clickedObject: Object3D) => void => {
  const [clickedObject, setClickedObject] = useState<Object3D>();
  const sortedObjects = useSceneStore(state => state.sortedObjects);
  const currentFSMState = useFSMStore(state => state.currentFSMState);
  const setFSMState = useFSMStore(state => state.setFSMState);


  // TODO onclick on gotoState typed object :
  //  >>> change current state <<<
  //  >>> back button <<<

  const handleGotoState = useCallback((obj?: Object3D) => setClickedObject(obj), []);

  useEffect(() => {
    if (clickedObject) {
      const isActive = isAnnotationActive(clickedObject, currentFSMState);
      if (isActive && sortedObjects['gotoState'].includes(clickedObject)) {
        const newState = clickedObject.userData.tags?.gotoState;
        setFSMState(newState);
      }
      setClickedObject(undefined); // otherwise when state changes it'll act as if clicked
    }
  }, [clickedObject, currentFSMState, setFSMState, sortedObjects]);

  return handleGotoState;
};