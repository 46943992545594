import React from 'react';
import {useSceneStore} from 'stores/useSceneStore';
import {useTranslation} from 'react-i18next';
// @ts-expect-error no declaration file...
import {CircleProgress} from 'react-gradient-progress';

import './LoadingFeedback.scss';

const LoadingFeedback: React.FC = () => {
    const {t} = useTranslation('translations');
    const percentageLoaded = useSceneStore(state => state.percentageLoaded);

    return (
        <div className="loading-feedback">
            <div className="circle">
                <CircleProgress percentage={Math.round(percentageLoaded)} width={200} strokeWidth={16}
                                primaryColor={['#fec00c', '#f15a22']}/>
            </div>
            <div className="text">
                {t('page.loading_message')}
            </div>
        </div>
    );
};

export default LoadingFeedback;